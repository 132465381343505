body {
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

.container {
  margin-top: 50px;
  margin-bottom: 100px;
}


/*TODO: Set the font weight of the first table column to bold*/